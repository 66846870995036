import React, { forwardRef, useEffect, useRef } from "react"
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

import { arrow, horizontalScrollable, imageContainer } from "./filmGroup.module.css"

const FILM_COVER_WIDTH = 160;
const PADDING_RIGHT_2 = 8; // 8px as pr-2 is 0.5rem

// Cannot use the ref directly on the icon, so we need to wrap it in a span
const LeftArrow = forwardRef((props, ref) => <span ref={ref}><MdChevronLeft {...props} /></span>);
const RightArrow = forwardRef((props, ref) => <span ref={ref}><MdChevronRight {...props} /></span>);

const FilmGroup = ({ title, id, films }) => {
  const sectionRef = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const scroll = (isForward) => {
    const scrollAmount = isForward ? FILM_COVER_WIDTH + PADDING_RIGHT_2 : -(FILM_COVER_WIDTH + PADDING_RIGHT_2);
    sectionRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
  }


  const onSectionScroll = () => {
    const scrollPosition = sectionRef.current.scrollLeft;
    const visibleAreaWidth = sectionRef.current.getBoundingClientRect().width;

    if (visibleAreaWidth === sectionRef.current.scrollWidth) {
      leftArrowRef.current.style.display = "none";
      rightArrowRef.current.style.display = "none";
    } else {
      if (scrollPosition > 0) {
        leftArrowRef.current.style.display = "block";
      } else {
        leftArrowRef.current.style.display = "none";
      }
      if (scrollPosition < sectionRef.current.scrollWidth - visibleAreaWidth) {
        rightArrowRef.current.style.display = "block";
      } else {
        rightArrowRef.current.style.display = "none";
      }
    }

    for (let filmCoverIndex = 0; filmCoverIndex < sectionRef.current.children.length; filmCoverIndex++) {
      const filmCover = sectionRef.current.children[filmCoverIndex];

      const filmCoverStart = filmCoverIndex * FILM_COVER_WIDTH;
      const filmCoverEnd = ((filmCoverIndex + 1) * FILM_COVER_WIDTH) + (filmCoverIndex * PADDING_RIGHT_2);

      // Styling the film cover with a partial opacity when it's partially visible
      if (filmCoverStart >= scrollPosition && filmCoverEnd <= (scrollPosition + visibleAreaWidth)) {
        filmCover.style.opacity = 1;
      } else {
        filmCover.style.opacity = 0.5;
      }
    }
  };

  // Update the view at start. Using the second parameter as [] allows to call the function once
  useEffect(onSectionScroll, []);

  return <>
    <h2 id={id} className="uppercase py-4">{title}</h2>
    <div className="relative">
      <section
        className={`${horizontalScrollable} inline-flex w-full overflow-x-scroll`}
        onScroll={onSectionScroll}
        ref={sectionRef}
      >
        {films.map(({ title: filmTitle, image, slug }, index) => {
          return <article key={index} className="pr-2 h-64">
            <Link to={`/piccoli-sollievi/cineteca/${slug}`}>
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={filmTitle}
                className={`${imageContainer} rounded-lg`}
              />
            </Link>
          </article>
        })}
      </section>
      <LeftArrow size={50} className={`${arrow}`} ref={leftArrowRef} onClick={() => scroll(false)} />
      <RightArrow size={50} className={`${arrow} right-0`} ref={rightArrowRef} onClick={() => scroll(true)} />
    </div>
  </>;
};

export default FilmGroup;
