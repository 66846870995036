import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import Hero from "../../../components/Hero"
import FilmGroup from "../../../components/FilmGroup"
import Button from "../../../components/Button"

const query = graphql`
  fragment FilmInformation on MarkdownRemark {
    frontmatter {
      title
      slug
      image {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED,
            height: 250,
            width: 160,
          )
        }
      }
    }
  }

  {
    malattiaFisicaEDisabilita: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sulla malattia fisica e la disabilità"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    disagioMentale: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sul disagio mentale"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    relazioni: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sulle relazioni sentimentali"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    adolescenza: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sull'adolescenza"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    conflittoEGuerra: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sul conflitto e la guerra"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    diversita: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sulla diversità"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    esistenziali: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film esistenziali"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    rapportoConGenitori: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sul rapporto con i genitori"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
    trauma: allFile(
      filter: {
        sourceInstanceName: {eq: "film"},
        childMarkdownRemark: {
          frontmatter: {
            category: {eq: "Film sul trauma"}
          }
        }
      }
      sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___year}
    ) {
      nodes {
        childMarkdownRemark {
          ...FilmInformation
        }
      }
    }
  }
`;

const mapNodeFrontmatter = (node) => node.childMarkdownRemark.frontmatter;

const CinetecaPage = () => {
  const data = useStaticQuery(query);
  const allFilms = {
    malattiaFisicaEDisabilita: data.malattiaFisicaEDisabilita.nodes.map(mapNodeFrontmatter),
    disagioMentale: data.disagioMentale.nodes.map(mapNodeFrontmatter),
    relazioni: data.relazioni.nodes.map(mapNodeFrontmatter),
    adolescenza: data.adolescenza.nodes.map(mapNodeFrontmatter),
    conflittoEGuerra: data.conflittoEGuerra.nodes.map(mapNodeFrontmatter),
    diversita: data.diversita.nodes.map(mapNodeFrontmatter),
    esistenziali: data.esistenziali.nodes.map(mapNodeFrontmatter),
    rapportoConGenitori: data.rapportoConGenitori.nodes.map(mapNodeFrontmatter),
    trauma: data.trauma.nodes.map(mapNodeFrontmatter),
  };

  return <Layout>
    <Seo
      title="Cineteca"
      description="Se la nostra vita è una storia di cui noi siamo il protagonista, allora il cinema e la letteratura possono trasformarsi in un farmaco. Per questo motivo vi propongo alcuni spunti per trattare le nostre ferite interne"
    />
    <Hero title="Cineteca" />
    <div className="grid grid-cols-6 py-6">
      <div className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
        <FilmGroup title="Film sulla malattia fisica e la disabilità" films={allFilms.malattiaFisicaEDisabilita} />
        <FilmGroup title="Film sul disagio mentale" films={allFilms.disagioMentale} />
        <FilmGroup title="Film sulle relazioni sentimentali" films={allFilms.relazioni} />
        <FilmGroup title="Film sull'adolescenza" films={allFilms.adolescenza} />
        <FilmGroup title="Film sul conflitto e la guerra" films={allFilms.conflittoEGuerra} />
        <FilmGroup title="Film sulla diversità" films={allFilms.diversita} />
        <FilmGroup title="Film esistenziali" films={allFilms.esistenziali} />
        <FilmGroup title="Film sul rapporto con i genitori" films={allFilms.rapportoConGenitori} />
        <FilmGroup title="Film sul trauma" films={allFilms.trauma} />

        <div className="text-center mt-10">
          <Button text="Ritorna ai Piccoli Sollievi" to="/piccoli-sollievi" />
        </div>
      </div>
    </div>
  </Layout>;
};

export default CinetecaPage;
