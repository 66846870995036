import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"
import Button from "../../components/Button"
import BlogArticleCard from "../../components/BlogArticleCard"
import ExternalArticleCard from "../../components/ExternalArticleCard"
import externalArticles from "../../constants/externalArticles"

const query = graphql`
{
  latestBlogArticles: allFile(
    filter: {sourceInstanceName: {eq: "blog"}}
    sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___date}
    limit: 2
  ) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          slug
          title
          description
          date(formatString: "YYYY-MM-DD")
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`;

const ArticoliPage = () => {
  const { latestBlogArticles } = useStaticQuery(query);

  return <Layout>
    <Seo title="Articoli" />
    <Hero title="Articoli" />

    {/** Header section */}
    <section className="grid grid-cols-6 py-6">
      <article className="col-span-6 md:col-start-2 md:col-span-2 px-5">
        <h2 className="mb-4 font-bold">Qualche Riflessione</h2>
        <p className="mb-4">Di seguito potete trovare alcuni miei articoli, scritti nel corso degli anni, relativi a diverse <b>tematiche psicologiche</b>. La ricerca e l'approfondimento sono infatti parte integrante del mestiere dello psicologo e la loro fiamma dovrebbe essere sempre alimentata perchè, per citare Kubrick: "<i>Per quanto vasta sia l'oscurità, dobbiamo fornire la nostra luce</i>."</p>
        <p className="mb-4">Allo stesso tempo, non ho alcuna pretesa che questi scritti siano esaustivi e mettano la parola fine sulle questioni che trattano, ma ho solo la speranza che possano alimentare a loro volta <b>curiosità</b> e <b>desiderio di ricerca</b>. Per quanto vasta sia la profondità della mente, rimarrà sempre infatti uno scarto nella comprensione delle cose. Per dirla come Shakespeare: "Ci sono più cose in cielo e in terra, Orazio, di quante ne sogni la tua filosofia".</p>
      </article>
      <article className="col-span-6 md:col-span-2 px-5 text-center">
        <StaticImage
          src="../../assets/a-young-man-reading-at-candlelight.jpg"
          alt="Matthias Stom - A Young Man Reading at Candlelight"
        />
        <br />
        <span className="text-xs">Matthias Stom - A Young Man Reading at Candlelight</span>
      </article>
    </section>

    <div className="grid grid-cols-6">
      <hr className="col-span-6 md:col-start-2 md:col-span-4 mb-8 mx-5 text-grey h-2" />
    </div>

    {/** Latest two blog posts plus the link to the first blog page (as it has pagination) */}
    <section className="grid grid-cols-6">
      <div className="col-span-6 md:col-span-4 md:col-start-2">
        <h2 className="px-5 mb-4">Ultimi blog post</h2>
      </div>
      <div className="col-span-6 md:col-span-4 md:col-start-2 grid grid-cols-2 gap-y-8">
        {latestBlogArticles.nodes.map((article, index) => {
          const {
            image,
            date,
            title,
            slug,
            description,
          } = article.childMarkdownRemark.frontmatter;

          return <article key={index} className="col-span-2 md:col-span-1 px-5">
            <BlogArticleCard
              title={title}
              date={date}
              description={description}
              slug={slug}
              image={image.childImageSharp.gatsbyImageData}
            />
          </article>;
        })}
      </div>
      <div className="col-span-6 my-8 text-center">
        <Button to="/blog/" text="Tutti i post" />
      </div>
    </section>

    <div className="grid grid-cols-6">
      <hr className="col-span-6 md:col-start-2 md:col-span-4 mb-8 mx-5 text-grey h-2" />
    </div>

    {/** External articles */}
    <section className="grid grid-cols-6 mb-8">
      <div className="col-span-6 md:col-span-4 md:col-start-2">
        <h2 className="px-5 mb-4">Articoli scritti su altri siti</h2>
      </div>
      <div className="col-span-6 md:col-span-4 md:col-start-2 grid grid-cols-2 gap-y-8">

        {externalArticles.map((externalArticle, index) => {
          const { title, imageName, link } = externalArticle;

          return <article key={index} className="col-span-2 md:col-span-1 px-5">
            <ExternalArticleCard
              title={title}
              imageName={imageName}
              link={link}
            />
          </article>
        })}
      </div>
    </section>
  </Layout>;
};

export default ArticoliPage;
